<template>
    <div>
        <vue-header :fixed="true" title="红包管理" />
        <div>
            <vueTime :returnFun="TimeFun" />
            <div v-if="info">
                <div class="user-yoo" v-if="info.amount_id">
                    <div class="flex" v-if="tongji">
                        <div class="flex-1">
                            <div class="user-num">{{tongji.sifa_count}}</div>
                            <div>私发红包数</div>
                        </div>
                        <div class="flex-1">
                            <div  class="user-num">{{tongji.qufa_count}}</div>
                            <div>群发红包数</div>
                        </div>
                        <div class="flex-1">
                            <div  class="user-num">{{tongji.lingqu_count}}</div>
                            <div>红包领取数</div>
                        </div>
                        <div class="flex-1">
                            <div  class="user-num">{{tongji.no_lingqu_count}}</div>
                            <div>未领红包数</div>
                        </div>
                        <div class="flex-1">
                            <div  class="user-num">{{tongji.hongbao_test_count}}</div>
                            <div>评估次数</div>
                        </div>
                    </div>
                </div>

                <div class="user-yoo flex" v-else-if="tongji">
                    <div class="flex-1">
                        <div class="user-num">{{tongji.user_lingqu_count}}</div>
                        <div>红包领取数</div>
                    </div>
                    <div class="flex-1">
                        <div  class="user-num">{{tongji.user_no_hongbao_test_count}}</div>
                        <div>待评估</div>
                    </div>
                    <div class="flex-1">
                        <div  class="user-num">{{tongji.user_test_count}}</div>
                        <div>已评估</div>
                    </div>
                </div>
            
                <div class="red-list flex" v-if="info && info.amount_id">
                    <div class="red-li flex-1 flex flex-y flex-x" :class="{active:type==1}" @click="typeFun(1)">
                        未赠送
                    </div>
                    <div class="red-li flex-1 flex flex-y flex-x" :class="{active:type==2}" @click="typeFun(2)">
                        待领取
                    </div>
                    <div class="red-li flex-1 flex flex-y flex-x" :class="{active:type==3}" @click="typeFun(3)">
                        已领取
                    </div>
                    <div class="red-li flex-1 flex flex-y flex-x" :class="{active:type==4}" @click="typeFun(4)">
                        已过期
                    </div>
                </div>

                <div class="red-list flex"  v-else-if="info">
                    <div class="red-li flex-1 flex flex-y flex-x" :class="{active:type==1}" @click="typeFun(3,1)">
                        待评估
                    </div>
                    <div class="red-li flex-1 flex flex-y flex-x" :class="{active:type==2}" @click="typeFun(3,2)">
                        已评估
                    </div>
                    <div class="red-li flex-1 flex flex-y flex-x"></div>
                    <div class="red-li flex-1 flex flex-y flex-x"></div>
                </div>
            </div>

            <div v-if="info.amount_id">
                <div class="red-list-s flex" v-for="(items, index) in lingqu" :key="index" >
                    <div class="red-list-img">
                        <img src="../../../../images/redEnvelope/gift.png"/>
                    </div>
                    <div class="flex-1 flex flex-y">
                        <div class="flex-1">
                            <div class="flex">
                                <div v-if="type!==3">{{items.test_count}}次{{items.type==1?'私发':'群发'}}评估红包</div>
                                <div v-else>{{items.user.name||items.user.nikeName}}领取了{{items.type==1?'私发':'群发'}}评估红包</div>
                                <div v-if="type!==3" class="red-list-ci">剩余<span>{{items.test_count-items.use_count}}</span>次</div>
                            </div>
                            <div class="red-list-time" v-if="type==1">{{items.end_date}}前可用</div>
                            <div class="red-list-time" v-if="type==2">自动退回倒计时：{{items.end_date}}</div>
                            <div class="red-list-time" v-if="type==3">领取时间：{{items.created_at}}</div>
                            <div class="red-list-time" v-if="type==4">到期时间：{{items.end_date}}</div>
                        </div>
                        <div class="red-list-button" v-if="type==1" @click="()=>giveFun(items.hongbao_code)">赠送红包</div>
                        <div class="red-list-button" v-if="type==2 && items.is_cancel==1" @click="()=>cancelFun(items.hongbao_code)">取消赠送</div>
                        <div class="red-list-button red-hui" v-if="type==2 && items.is_cancel==2">已取消</div>
                        <div class="red-list-button" v-if="type==3 && items.is_use==2" @click="()=>lookFun(items.report_token)">查看报告</div>
                        <div class="red-list-button red-hui" v-if="type==3 && items.is_use==1">已领取</div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="red-list-s flex" v-for="(items, index) in lingqu" :key="index">
                    <div class="red-list-img">
                        <img src="../../../../images/redEnvelope/gift.png"/>
                    </div>
                    <div class="flex-1 flex flex-y">
                        <div class="flex-1">
                            <div class="flex">
                                <div>领取了{{items.type==1?'私发':'群发'}}红包</div>
                            </div>
                            <div class="red-list-time" v-if="type==1">领取时间：{{items.created_at}}</div>
                            <div class="red-list-time" v-if="type==2">评估时间：{{items.use_at}}</div>
                        </div>
                        <div class="red-list-button" v-if="type==1 && !is_expire" @click="()=>estimateFun({
                            test_code:items.test_code,
                            id:info.id
                        })">前往评估</div>
                        <div class="red-list-button" v-if="type==1 && is_expire">已过期</div>
                        <div class="red-list-button" v-if="type==2" @click="()=>lookFun(items.report_token)">查看报告</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert" v-if="shareBool" @click="closeFun">
            <div class="alert-top">
                <img src="../../../../images/share.png" class="alert-share" />
            </div>
        </div>
    </div>
</template>
<script>
import vueHeader from '@/components/header';
import vueTime from '@/components/time';
import { mapState, mapActions, mapMutations } from "vuex";
import { dateZero } from "@/utils";
import { Dialog } from 'vant';

export default {
  name: 'manage',
  components:{
    vueHeader,
    vueTime
  },
  data(){ 
        return {
            type:1,
            start_time:'',
            end_time:'',
            shareBool:false
        }
    },
  created:function(){ 
      this.configData();
      if(!this.info){
        this.axiosMain({
            fun:this.accountFun
        });
      }
  },
  computed:{
        ...mapState('redEnvelope',['lingqu','tongji']),
        ...mapState('profile',['info']),
  },
  methods:{
      closeFun(){
          this.shareBool=false;
          this.typeFun(1);
      },
      estimateFun(obj){        //前往评估
            this.axiosRedCanReport({
                data:{
                    use_hongbao:1,
                    test_code:obj.test_code
                },
                fun:(data)=>{
                    this.$router.push({
                        path:'/confirmAssess/15',
                        query:{
                            uid:obj.id,
                            red:obj.test_code
                        }
                    });
                }
            })
      },
      lookFun(token){
            this.$router.push({
                path:`/report/${token}`,
            });
      },
      giveFun(code){            //赠送红包
        let _this = this;
        Dialog.confirm({
            title: '温馨提示',
            message: '健康红包一旦赠送成功，将无法撤回和修改，请确认无误后再赠送哦!',
            confirmButtonText:'继续赠送',
            cancelButtonText:'我再想想',
        })
        .then(() => {
            _this.axiosHongbaoSend({
                data:{
                    hongbao_code:code
                },
                fun:()=>{
                    _this.shareBool = true;
                    let wx  = window.wx;
                    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                        wx.updateAppMessageShareData({ 
                            title: `${_this.info.name||_this.info.nikeName}送您健康大红包啦`, // 分享标题
                            desc: '送您一张AI中医智能评估卷,随时掌握健康状态！', // 分享描述
                            link: `${document.location.protocol}//${window.location.host}/redEnvelope/packet/${code}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                            success: function () {} 
                        })
                        wx.updateTimelineShareData({ 
                            title: `${_this.info.name||_this.info.nikeName}送您健康大红包啦`, // 分享标题
                            link: `${document.location.protocol}//${window.location.host}/redEnvelope/packet/${code}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                            success: function () {}
                        })
                    });
                }
            });
        }).catch(() => {
            _this.axiosHongbaoCancel({
                data:{
                    hongbao_code:code
                }
            })
        });    
      },
      cancelFun(hongbao_code){          //取消红包
            this.axiosHongbaoCancel({
                data:{
                    hongbao_code
                },
                fun:()=>{
                    this.typeFun(2);
                }
            })
      },
      accountFun(data){
          console.log(data);
          
          let time = new Date();
          let start_time = `${time.getFullYear()}-${dateZero(time.getMonth()+1)}-01`;
          let end_time = `${time.getFullYear()}-${dateZero(time.getMonth()+1)}-${dateZero(time.getDate())}`;
          console.log(3);
          this.axiosLingquList({
                start_time,
                end_time,
                status:this.type,
                page:1,
                page_size:50
          });
          console.log(1);
          this.axiosTongji({
              start_time,
              end_time,
              type:data.amount_id?1:2
          });
      },
      TimeFun(time){
          this.start_time = time.start1;
          this.end_time = time.end1;
          if(this.info){
              if(this.info.amount_id){
                  this.axiosLingquList({
                    start_time:time.start1,
                    end_time:time.end1,
                    status:this.type,
                    page:1,
                    page_size:50
                });
                this.axiosTongji({
                    start_time:time.start1,
                    end_time:time.end1,
                    status:this.type,
                    type:this.info.amount_id?1:2
                });
              }else{
                this.typeFun(3,1);
              }
          }
      },
      typeFun(v,s=0){
            this.type = s?s:v;
            if(v==3){
                this.axiosTestList({
                    start_time:this.start_time,
                    end_time:this.end_time,
                    is_use:s,
                    page:1,
                    page_size:50,
                    type:this.info.amount_id?2:1
                });
            }else{
                this.axiosLingquList({
                    start_time:this.start_time,
                    end_time:this.end_time,
                    status:this.type,
                    page:1,
                    page_size:50
                });
            }
             this.axiosTongji({
                start_time:this.start_time,
                end_time:this.end_time,
                status:v,
                type:this.info.amount_id?1:2
            });
      },
      ...mapActions('redEnvelope',['axiosAccount','axiosLingquList','axiosTongji','axiosTestList','axiosRed','axiosHongbaoCancel','axiosRedCanReport','axiosHongbaoSend']),
      ...mapActions('profile',['axiosMain']),
      ...mapActions('user',['configData'])
  }
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'manage';
</style>